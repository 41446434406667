var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthenticated
    ? _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _c("div", { staticClass: "footer-copyright" }, [
                  _c("b", [
                    _vm._v(
                      "© " +
                        _vm._s(_vm._f("moment")(new Date(), "YYYY")) +
                        ", Aliventa GmbH"
                    )
                  ]),
                  _vm._v(" — Grüne-Hex ADMIN-AREA "),
                  _c("span", { staticClass: "tag" }, [_vm._v("v1.0.0")])
                ])
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }