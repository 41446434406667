var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthenticated
    ? _c(
        "nav",
        { staticClass: "navbar is-fixed-top", attrs: { id: "navbar-main" } },
        [
          _c("div", { staticClass: "navbar-brand" }, [
            _c(
              "a",
              {
                staticClass: "navbar-item is-hidden-desktop",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.menuToggleMobile.apply(null, arguments)
                  }
                }
              },
              [
                _c("b-icon", {
                  key: _vm.navMenuKey,
                  attrs: {
                    icon: _vm.menuToggleMobileIcon,
                    pack: _vm.menuToggleMobileIconPack
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "navbar-brand is-right" }, [
            _c(
              "a",
              {
                staticClass:
                  "navbar-item navbar-item-menu-toggle is-hidden-desktop",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.menuNavBarToggle.apply(null, arguments)
                  }
                }
              },
              [
                _c("b-icon", {
                  key: _vm.userMenuKey,
                  attrs: {
                    icon: _vm.menuNavBarToggleIcon,
                    pack: _vm.menuNavBarToggleIconPack,
                    "custom-size": "default"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "navbar-menu fadeIn animated faster",
              class: { "is-active": _vm.isMenuNavBarActive }
            },
            [
              _c(
                "div",
                { staticClass: "navbar-end" },
                [
                  _c("b-navbar-item", { staticClass: "has-divider" }, [
                    _c("span", [_vm._v(_vm._s(_vm.getProfile.name))])
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "navbar-item is-desktop-icon-only",
                      attrs: { title: "Log out" },
                      on: { click: _vm.logout }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: _vm.$func.getIcon("logout").icon,
                          pack: _vm.$func.getIcon("logout").pack,
                          "custom-size": "default"
                        }
                      }),
                      _c("span", [_vm._v("Logout")])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }