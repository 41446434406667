<template>
  <footer v-if="isAuthenticated" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ new Date() | moment("YYYY") }}, Aliventa GmbH</b>
              &mdash; Grüne-Hex ADMIN-AREA
              <span class="tag">v1.0.0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterBar",
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
