import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import api from "@/utils/api.js";

const state = {
  token: localStorage.getItem("access_token") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      api.get("/sanctum/csrf-cookie").then(() => {
        // Login
        api
          .post("/login", user)
          .then(function(response) {
            if (response.data.status_code !== 200) {
              commit(AUTH_ERROR, response.data.message);
              localStorage.removeItem("access_token");
              reject(response.data.message);
            } else {
              localStorage.setItem("access_token", response.data.access_token);
              api.setBearer(response.data.access_token);

              // validate that user is an ADMIN
              dispatch(USER_REQUEST)
                .then(() => {
                  // user is admin, allow access
                  commit(AUTH_SUCCESS, response);
                  resolve(response);
                })
                .catch(e => {
                  // user is not an admin - auto-logout
                  commit(AUTH_ERROR, e);
                  localStorage.removeItem("access_token");
                  reject(e);
                });
            }
          })
          .catch(e => {
            // error happened - auto-logout
            commit(AUTH_ERROR, e);
            localStorage.removeItem("access_token");
            reject(e);
          });
      });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      api.removeAuthorization();
      localStorage.removeItem("access_token");
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.data.access_token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
