/* Main Styles */
import "@/scss/main.scss";

/* Font Awesome - optimized for chunks */
import(
  /* webpackChunkName: "fontawesome" */ "@fortawesome/fontawesome-free/css/fontawesome.min.css"
);
import(
  /* webpackChunkName: "fa-solid-css" */ "@fortawesome/fontawesome-free/css/solid.min.css"
);
import(
  /* webpackChunkName: "fa-solid" */ "@fortawesome/fontawesome-free/js/solid.min.js"
);

/* Core */
import Vue from "vue";
import Buefy from "buefy";
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import { func } from "@/utils/func.js";

/* Components */
import axios from "axios";
import moment from "vue-moment";

/* Default title tag */
const defaultDocumentTitle = "[GRÜNE HEX] Admin-UI";

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

Vue.config.productionTip = false;

Vue.use(moment);
Vue.use(Buefy, {
  defaultSnackbarPosition: "is-top",
  defaultFirstDayOfWeek: 1,
  defaultDatetimeFormatter: date =>
    new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "CET"
    }).format(date),
  defaultDateFormatter: date =>
    new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "CET"
    }).format(date),
  defaultTimeFormatter: "HH:mm",
  defaultIconPack: "fas"
});

axios.defaults.baseURL = "http://localhost:8080";
Vue.prototype.$func = func;
Vue.prototype.$moment.defaultFormat = "DD.MM.YYYY, HH:mm";

const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch("/runtimeConfig.json");
  return await runtimeConfig.json();
};

getRuntimeConfig()
  .then(json => {
    const config = {
      API: {
        endpoint: json.API_ENDPOINT
      }
    };

    if (process.env.VUE_APP_API_ENDPOINT !== undefined) {
      axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
    } else {
      axios.defaults.baseURL = config.API.endpoint;
    }

    const token = localStorage.getItem("access_token");

    if (token !== null) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  })
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  });
