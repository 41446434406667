import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    meta: {
      title: "Dashboard",
      requiresAuth: true
    },
    path: "/",
    name: "home",
    component: Home
  },
  {
    meta: {
      title: "Login",
      guest: true
    },
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    meta: {
      title: "Galerien",
      requiresAuth: true
    },
    path: "/galleries",
    name: "galleries",
    component: () =>
      import(/* webpackChunkName: "galleries" */ "../views/Galleries.vue")
  },
  {
    meta: {
      title: "Galerie anlegen",
      requiresAuth: true
    },
    path: "/gallery/new",
    name: "gallery.new",
    props: true,
    component: () =>
      import(/* webpackChunkName: "gallery-new" */ "../views/GalleryNew.vue")
  },
  {
    meta: {
      title: "Galerie bearbeiten",
      requiresAuth: true
    },
    path: "/gallery/:id",
    name: "gallery.edit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "gallery-edit" */ "../views/GalleryEdit.vue")
  },
  {
    meta: {
      title: "Benutzerverwaltung",
      requiresAuth: true
    },
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue")
  },
  {
    meta: {
      title: "Benutzer anlegen",
      requiresAuth: true
    },
    path: "/user/new",
    name: "user.new",
    props: true,
    component: () =>
      import(/* webpackChunkName: "user-new" */ "../views/UserNew.vue")
  },
  {
    meta: {
      title: "Benutzer bearbeiten",
      requiresAuth: true
    },
    path: "/user/:id",
    name: "user.edit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "user-edit" */ "../views/UserEdit.vue")
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") === null) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
