var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-component",
    [
      _c("div", { staticClass: "level is-mobile", on: { click: _vm.click } }, [
        _c("div", { staticClass: "level-item" }, [
          _c("div", { staticClass: "is-widget-label" }, [
            _c("h3", { staticClass: "subtitle is-spaced" }, [
              _vm._v(" " + _vm._s(_vm.label) + " ")
            ]),
            _c(
              "h1",
              { staticClass: "title" },
              [
                _c("growing-number", {
                  attrs: {
                    value: _vm.number,
                    prefix: _vm.prefix,
                    suffix: _vm.suffix
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm.icon
          ? _c("div", { staticClass: "level-item has-widget-icon" }, [
              _c(
                "div",
                { staticClass: "is-widget-icon" },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.icon,
                      pack: _vm.iconPack,
                      size: "is-large",
                      type: _vm.type
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c("b-loading", {
        attrs: { "is-full-page": false, active: _vm.isLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }