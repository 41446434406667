<template>
  <card-component>
    <div @click="click" class="level is-mobile">
      <div class="level-item">
        <div class="is-widget-label">
          <h3 class="subtitle is-spaced">
            {{ label }}
          </h3>
          <h1 class="title">
            <growing-number :value="number" :prefix="prefix" :suffix="suffix" />
          </h1>
        </div>
      </div>
      <div v-if="icon" class="level-item has-widget-icon">
        <div class="is-widget-icon">
          <b-icon :icon="icon" :pack="iconPack" size="is-large" :type="type" />
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" :active="isLoading"></b-loading>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import GrowingNumber from "@/components/GrowingNumber";
export default {
  name: "CardWidget",
  components: { GrowingNumber, CardComponent },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    iconPack: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    number: {
      type: Number,
      default: 0
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
