var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: { "is-active": _vm.isDropdownActive } },
    [
      _c(
        _vm.componentIs,
        {
          tag: "component",
          class: {
            "has-icon": !!_vm.item.icon,
            "has-dropdown-icon": _vm.hasDropdown
          },
          attrs: {
            to: _vm.itemTo,
            href: _vm.itemHref,
            "exact-active-class": "is-active"
          },
          on: { click: _vm.menuClick }
        },
        [
          _vm.item.icon
            ? _c("b-icon", {
                class: { "has-update-mark": _vm.item.updateMark },
                attrs: {
                  icon: _vm.item.icon,
                  pack: _vm.item.iconPack,
                  "custom-size": "default"
                }
              })
            : _vm._e(),
          _vm.item.label
            ? _c("span", { class: { "menu-item-label": !!_vm.item.icon } }, [
                _vm._v(_vm._s(_vm.item.label))
              ])
            : _vm._e(),
          _vm.hasDropdown
            ? _c(
                "div",
                { staticClass: "dropdown-icon" },
                [
                  _c("b-icon", {
                    key: _vm.dropdownIconKey,
                    attrs: {
                      icon: _vm.dropdownIcon,
                      pack: _vm.dropdownIconPack,
                      "custom-size": "default"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.hasDropdown
        ? _c("aside-menu-list", {
            attrs: { menu: _vm.item.menu, "is-submenu-list": true }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }