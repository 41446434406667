<template>
  <div>
    <title-bar title="Dashboard" />
    <section class="section is-main-section">
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          :icon="$func.getIcon('user').icon"
          :number="num.users.count"
          :isLoading="isLoading.users"
          label="Benutzer"
          @click="$router.push({ name: 'users' })"
        />
        <card-widget
          class="tile is-child"
          type="is-primary"
          :icon="$func.getIcon('gallery').icon"
          :number="num.galleries.count"
          :isLoading="isLoading.galleries"
          label="Galerien"
          @click="$router.push({ name: 'galleries' })"
        />
        <card-widget
          class="tile is-child"
          type="is-primary"
          :icon="$func.getIcon('photo').icon"
          :number="num.galleries.photosCount"
          :isLoading="isLoading.galleries"
          label="Bilder"
          @click="$router.push({ name: 'galleries' })"
        />
      </tiles>
    </section>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import TitleBar from "@/components/TitleBar";
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
export default {
  name: "Home",
  components: {
    CardWidget,
    Tiles,
    TitleBar
  },
  data() {
    return {
      num: {
        users: {
          count: 0
        },
        galleries: {
          count: 0,
          photosCount: 0
        }
      },
      isLoading: {
        users: false,
        galleries: false
      }
    };
  },
  computed: {
    titleStack() {
      return ["Admin", "Dashboard"];
    }
  },
  mounted() {
    this.loadUserStatistics();
    this.loadGalleriesStatistics();
  },
  methods: {
    loadUserStatistics() {
      this.isLoading.users = true;
      api
        .get("/users")
        .then(r => {
          if (r.data) {
            this.num.users.count = r.data.length;
          }
        })
        .finally(() => (this.isLoading.users = false));
    },
    loadGalleriesStatistics() {
      this.isLoading.galleries = true;
      api
        .get("/galleries")
        .then(r => {
          if (r.data) {
            this.num.galleries.count = r.data.length;
            r.data.forEach(g => {
              this.num.galleries.photosCount += g.num_photos;
            });
          }
        })
        .finally(() => (this.isLoading.galleries = false));
    }
  }
};
</script>
