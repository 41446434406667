var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section is-title-bar" }, [
    _c("div", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("ul", [_c("li", [_vm._v(_vm._s(_vm.title))])])
        ])
      ]),
      _c("div", { staticClass: "level-left" }, [
        _vm.image
          ? _c("img", {
              staticClass: "img-max-height",
              attrs: { src: _vm.image, border: "0", alt: _vm.title }
            })
          : _vm._e()
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasRightVisible,
              expression: "hasRightVisible"
            }
          ],
          staticClass: "level-right"
        },
        [
          _c("div", { staticClass: "level-item" }, [
            _c("div", { staticClass: "buttons is-right" }, [_vm._t("right")], 2)
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }