<template>
  <aside v-if="isAuthenticated" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <span slot="label"><b>Grüne Hex ADMIN-AREA</b></span>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list v-else :key="index" :menu="menuGroup" />
      </template>
      <b-loading :is-full-page="false" :active="isLoading.menu"></b-loading>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import AsideTools from "@/components/AsideTools";
import AsideMenuList from "@/components/AsideMenuList";
export default {
  name: "AsideMenu",
  components: {
    AsideTools,
    AsideMenuList
  },
  props: {},
  data() {
    return {
      menu: [
        "Allgemein",
        [
          {
            to: "/",
            icon: this.$func.getIcon("home").icon,
            label: "Dashboard"
          },
          {
            to: "/galleries",
            icon: this.$func.getIcon("gallery").icon,
            label: "Galerien"
          }
        ],
        "Benutzerverwaltung",
        [
          {
            to: "/users",
            label: "Benutzer",
            icon: this.$func.getIcon("user").icon,
            iconPack: this.$func.getIcon("user").pack
          }
        ]
      ],
      isLoading: {
        menu: false
      }
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
