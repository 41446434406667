var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-bar", { attrs: { title: "Dashboard" } }),
      _c(
        "section",
        { staticClass: "section is-main-section" },
        [
          _c(
            "tiles",
            [
              _c("card-widget", {
                staticClass: "tile is-child",
                attrs: {
                  type: "is-primary",
                  icon: _vm.$func.getIcon("user").icon,
                  number: _vm.num.users.count,
                  isLoading: _vm.isLoading.users,
                  label: "Benutzer"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "users" })
                  }
                }
              }),
              _c("card-widget", {
                staticClass: "tile is-child",
                attrs: {
                  type: "is-primary",
                  icon: _vm.$func.getIcon("gallery").icon,
                  number: _vm.num.galleries.count,
                  isLoading: _vm.isLoading.galleries,
                  label: "Galerien"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "galleries" })
                  }
                }
              }),
              _c("card-widget", {
                staticClass: "tile is-child",
                attrs: {
                  type: "is-primary",
                  icon: _vm.$func.getIcon("photo").icon,
                  number: _vm.num.galleries.photosCount,
                  isLoading: _vm.isLoading.galleries,
                  label: "Bilder"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "galleries" })
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }