/**
 * Useful functions we want to use system-wide
 */
export const func = {
  /**
   * Return a icon for a named thing
   * @param {string} thing the thing you want a icon for
   * @returns {object} icon & pack information
   */
  getIcon: thing => {
    const result = { icon: null, pack: null };

    switch (thing) {
      /* Actions */
      case "active":
        result.icon = "check";
        result.pack = "fas";
        break;
      case "back":
        result.icon = "arrow-circle-left";
        result.pack = "fas";
        break;
      case "cancel":
        result.icon = "times";
        result.pack = "fas";
        break;
      case "disabled":
        result.icon = "ban";
        result.pack = "fas";
        break;
      case "delete":
        result.icon = "trash";
        result.pack = "fas";
        break;
      case "edit":
        result.icon = "edit";
        result.pack = "fas";
        break;
      case "inactive":
        result.icon = "times-circle";
        result.pack = "fas";
        break;
      case "mail":
        result.icon = "envelope";
        result.pack = "fas";
        break;
      case "list":
        result.icon = "list";
        result.pack = "fas";
        break;
      case "loading":
        result.icon = "spinner";
        result.pack = "fas";
        break;
      case "login":
        result.icon = "sign-in-alt";
        result.pack = "fas";
        break;
      case "logout":
        result.icon = "sign-out-alt";
        result.pack = "fas";
        break;
      case "new":
        result.icon = "plus";
        result.pack = "fas";
        break;
      case "sad":
        result.icon = "frown";
        result.pack = "fas";
        break;
      case "save":
        result.icon = "save";
        result.pack = "fas";
        break;
      case "search":
        result.icon = "search";
        result.pack = "fas";
        break;
      case "sort-up":
        result.icon = "sort-up";
        result.pack = "fas";
        break;
      case "sort-down":
        result.icon = "sort-down";
        result.pack = "fas";
        break;
      case "upload":
        result.icon = "upload";
        result.pack = "fas";
        break;
      /* Objects */
      case "gallery":
        result.icon = "images";
        result.pack = "fas";
        break;
      case "photo":
        result.icon = "image";
        result.pack = "fas";
        break;
      case "home":
        result.icon = "desktop";
        result.pack = "fas";
        break;
      case "password":
        result.icon = "key";
        result.pack = "fas";
        break;
      case "text":
        result.icon = "file-alt";
        result.pack = "fas";
        break;
      case "user":
        result.icon = "user";
        result.pack = "fas";
        break;
      case "user-detail":
        result.icon = "id-card";
        result.pack = "fas";
        break;
      case "user-new":
        result.icon = "user-plus";
        result.pack = "fas";
        break;
      case "users":
        result.icon = "users";
        result.pack = "fas";
        break;
      case "users-unverified":
        result.icon = "user-times";
        result.pack = "fas";
        break;
    }

    return result;
  }
};
