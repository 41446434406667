<template>
  <div id="app">
    <nav-bar />
    <aside-menu />
    <router-view />
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";

export default {
  name: "App",
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  }
};
</script>
