var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: { "menu-list": !_vm.isSubmenuList } },
    _vm._l(_vm.menu, function(item, index) {
      return _c("aside-menu-item", {
        key: index,
        attrs: { item: item },
        on: { "menu-click": _vm.menuClick }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }