var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm.title
      ? _c("header", { staticClass: "card-header" }, [
          _c(
            "p",
            { staticClass: "card-header-title" },
            [
              _vm.icon
                ? _c("b-icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: _vm.icon,
                      pack: _vm.iconPack,
                      "custom-size": "default"
                    }
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ],
            1
          ),
          _vm.headerIcon
            ? _c(
                "a",
                {
                  staticClass: "card-header-icon",
                  attrs: { href: "#", "aria-label": "more options" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.headerIconClick.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.headerIcon,
                      pack: _vm.headerIconPack,
                      "custom-size": "default"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }