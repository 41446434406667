var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthenticated
    ? _c(
        "aside",
        { staticClass: "aside is-placed-left is-expanded" },
        [
          _c("aside-tools", { attrs: { "is-main-menu": true } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _c("b", [_vm._v("Grüne Hex ADMIN-AREA")])
            ])
          ]),
          _c(
            "div",
            { staticClass: "menu is-menu-main" },
            [
              _vm._l(_vm.menu, function(menuGroup, index) {
                return [
                  typeof menuGroup === "string"
                    ? _c("p", { key: index, staticClass: "menu-label" }, [
                        _vm._v(" " + _vm._s(menuGroup) + " ")
                      ])
                    : _c("aside-menu-list", {
                        key: index,
                        attrs: { menu: menuGroup }
                      })
                ]
              }),
              _c("b-loading", {
                attrs: { "is-full-page": false, active: _vm.isLoading.menu }
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }