<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li>{{ title }}</li>
          </ul>
        </div>
      </div>
      <div class="level-left">
        <img
          v-if="image"
          :src="image"
          border="0"
          class="img-max-height"
          :alt="title"
        />
      </div>
      <div v-show="hasRightVisible" class="level-right">
        <div class="level-item">
          <div class="buttons is-right">
            <slot name="right" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    hasRightVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.img-max-height {
  max-height: 50px;
}
</style>
