<template>
  <nav v-if="isAuthenticated" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon
          :icon="menuToggleMobileIcon"
          :pack="menuToggleMobileIconPack"
          :key="navMenuKey"
        />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon
          :icon="menuNavBarToggleIcon"
          :pack="menuNavBarToggleIconPack"
          :key="userMenuKey"
          custom-size="default"
        />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <b-navbar-item class="has-divider">
          <span>{{ getProfile.name }}</span>
        </b-navbar-item>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click="logout"
        >
          <b-icon
            :icon="$func.getIcon('logout').icon"
            :pack="$func.getIcon('logout').pack"
            custom-size="default"
          />
          <span>Logout</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  name: "NavBar",
  data() {
    return {
      isMenuNavBarActive: false,
      userMenuKey: 0,
      navMenuKey: 0
    };
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState(["isAsideMobileExpanded"]),
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "times" : "ellipsis-h-alt";
    },
    menuNavBarToggleIconPack() {
      return this.isMenuNavBarActive ? "fas" : "fas";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "bars" : "bars";
    },
    menuToggleMobileIconPack() {
      return this.isAsideMobileExpanded ? "fad" : "fas";
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false;
    });
  },
  methods: {
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
      this.navMenuKey += 1;
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
      this.userMenuKey += 1;
    },
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    }
  }
};
</script>
