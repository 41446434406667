import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import store from "@/store"; // your vuex store
import router from "@/router"; // your vuex store
import { AUTH_LOGOUT } from "@/store/actions/auth";

axios.interceptors.response.use(undefined, function(e) {
  return new Promise(function() {
    if (e.response.status === 401 || e.response.status === 403) {
      // if you ever get an unauthorized, logout the user
      store.dispatch(AUTH_LOGOUT);
      // you can also redirect to /login if needed !
      router.push("/login");
    } else {
      const msg = e.response.data.message
        ? e.response.data.message
        : Object.values(e.response.data.errors)
            .map(ele => ele[0])
            .join(", ");
      Toast.open({
        message: `Fehler: ${msg}`,
        type: "is-danger",
        duration: 5000,
        queue: false
      });
    }
    throw e;
  });
});

export default {
  get(url, request) {
    let sUrl = `/api${url}`;
    if (url.startsWith("/sanctum")) {
      sUrl = url;
    }
    return axios
      .get(sUrl, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  post(url, request) {
    return axios
      .post(`/api${url}`, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  put(url, request) {
    return axios
      .put(`/api${url}`, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  patch(url, request) {
    return axios
      .patch(`/api${url}`, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  delete(url, request) {
    return axios
      .delete(`/api${url}`, request)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setBearer(token) {
    return (axios.defaults.headers.common.Authorization = `Bearer ${token}`);
  },
  removeAuthorization() {
    delete axios.defaults.headers.common.Authorization;
  }
};
