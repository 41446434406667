<template>
  <div class="aside-tools">
    <div class="aside-tools-label">
      <b-icon v-if="icon" :icon="icon" :pack="iconPack" custom-size="default" />
      <slot name="label" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideTools",
  props: {
    iconPack: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  }
};
</script>
