var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aside-tools" }, [
    _c(
      "div",
      { staticClass: "aside-tools-label" },
      [
        _vm.icon
          ? _c("b-icon", {
              attrs: {
                icon: _vm.icon,
                pack: _vm.iconPack,
                "custom-size": "default"
              }
            })
          : _vm._e(),
        _vm._t("label")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }